body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    /* Add other styles if needed */
}

.projects {
    text-align: center;
    margin: 0; /* Remove margin to allow background color to extend to screen edges */
    padding-top: 20px; /* Add padding at the top for spacing */
    padding-bottom: 20px; /* Add padding at the bottom for spacing */
    background-color: black;
    color: white; /* Ensure text is visible on dark background */
    min-height: 100vh; /* Make the projects section fill the screen */
}


.projects h1 {
    border-bottom: 2px solid #333;
    display: block; /* Changed from inline-block to block */
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    padding-bottom: 5px;
    color: white; /* H1 text color for visibility */
    width: 70%; /* Adjust the width as needed */
}


.project1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap; /* Allows items to wrap in smaller screens */
}

.clone-left,
.clone-right {
    flex-basis: 48%; /* Adjusts the width of each side */
    flex-grow: 1; /* Allows flex items to grow if there's space available */
}

.clone-left {
    text-align: center;
}

/* Other CSS remains the same */

.clone-right {
    text-align: center; /* Center aligns the content */
    display: flex;
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically */
}

.clone-right img {
    max-width: 50%; /* Controls the width of the image */
    height: 250px; /* Adjust this value to increase the height */
    border-radius: 5px;
    margin: 0 auto; /* Centers the image */
    transition: transform 0.3s ease-in-out; /* Smooth transition for the hover effect */
}

.clone-right img:hover {
    transform: scale(1.1); /* Scales up the image size on hover */
}


.clone-left h2 {
    margin-bottom: 10px;
}

.clone-left p {
    margin-bottom: 20px;
}

.clone-left a {
    margin-right: 10px;
    color: #ffffff; /* Link color for visibility */
    text-decoration: none;
}

.clone-left a:hover {
    color: #32CD32;
    text-decoration: underline;
}

.clone-left a:hover svg {
    transform: scale(1.1);
    transition: transform 0.2s ease-in-out;
}

/* Existing CSS for project1 remains the same */

/* Styles for project2 */
.project2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;
}

.rave-left,
.rave-right {
    flex-basis: 48%;
    flex-grow: 1;
    text-align: center;
}

.rave-left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.rave-left img {
    max-width: 50%;
    height: 250px;
    border-radius: 5px;
    margin: 0 auto;
    transition: transform 0.3s ease-in-out;
}

.rave-left img:hover {
    transform: scale(1.1);
}

.rave-right h2 {
    margin-bottom: 10px;
}

.rave-right p {
    margin-bottom: 20px;
}

.rave-right a {
    margin-right: 10px;
    color: #ffffff;
    text-decoration: none;
}

.rave-right a:hover {
    color: #32CD32;
    text-decoration: underline;
}

.rave-right a:hover svg {
    transform: scale(1.1);
    transition: transform 0.2s ease-in-out;
}

/* Reuse styles from project1 for project3 */

.project3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap; /* Allows items to wrap in smaller screens */
}
.crime-left,
.crime-right {
    flex-basis: 48%; /* Adjusts the width of each side */
    flex-grow: 1; /* Allows flex items to grow if there's space available */
}

.crime-left {
    text-align: center;
}

.crime-right {
    text-align: center; /* Center aligns the content */
    display: flex;
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically */
}

.crime-right img {
    max-width: 50%; /* Controls the width of the image */
    height: 250px; /* Adjust this value to increase the height */
    border-radius: 5px;
    margin: 0 auto; /* Centers the image */
    transition: transform 0.3s ease-in-out; /* Smooth transition for the hover effect */
}

.crime-right img:hover {
    transform: scale(1.1); /* Scales up the image size on hover */
}

.crime-left h2 {
    margin-bottom: 10px;
}

.crime-left p {
    margin-bottom: 20px;
}

.crime-left a {
    margin-right: 10px;
    color: #ffffff; /* Link color for visibility */
    text-decoration: none;
}

.crime-left a:hover {
    color: #32CD32;
    text-decoration: underline;
}

.crime-left a:hover svg {
    transform: scale(1.1);
    transition: transform 0.2s ease-in-out;
}

