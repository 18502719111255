.description {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    background-color: #000;
    color: #fff;
    height: calc(100vh - 70px); /* Subtract the height of the navbar */
    box-sizing: border-box;
}

.desc-left, .desc-right {
    flex: 1;
    padding: 0 15px; /* Add padding to prevent content from touching the edges */
}





.desc-left p,
.desc-left h1 {
    font-family: 'Arial', sans-serif; /* A clean, readable font */
}

.desc-left h1 {
    font-size: 3rem; /* Increased font size for h1 */
    color: #e0e0e0; /* Light grey for the title for better visibility */
    margin-bottom: 10px;
}

.desc-left p {
    font-size: 1.2rem;
    color: #d6d6d6; /* A lighter grey for paragraph to contrast against black */
    margin-bottom: 20px;
}

/* ... other styles ... */

.reach-out-button {
    display: inline-block; /* Makes the link behave like a button */
    background-color: #32CD32; /* Vibrant green for contrast */
    color: white;
    border: none;
    padding: 12px 19px; /* Adjusted padding for better sizing */
    cursor: pointer;
    font-size: 0.9rem; /* Slightly smaller font size */
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
    text-align: center;
    text-decoration: none; /* Removes underline from link */
    line-height: normal; /* Use normal line-height */
}

.reach-out-button:hover {
    background-color: #28a745; /* Slightly darker green on hover */
    color: #fff; /* Keep text white on hover */
}

/* ... other styles ... */


.desc-right {
    flex: 1;
    text-align: right;
}

.desc-right img {
    max-width: 100%;
    height: auto;
}
