.about {
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: #000; /* Black background for the section */
    min-height: 100vh;
    color: #fff; /* Set default text color to white for the entire section */
}

.ab-left {
    flex-basis: 30%;
    text-align: center;
    padding: 20px;
}

.ab-left img {
    width: 400px;
    height: 400px;
    border-radius: 50%;
    object-fit: cover;
}

.ab-right {
    flex-basis: 70%;
    padding: 20px;
}

.about h1 {
    font-size: 2.5rem;
    color: #e0e0e0; /* Light grey for better visibility */
    text-align: center;
    margin-bottom: 30px;
    font-family: 'Arial', sans-serif;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Adjusted for contrast */
    font-weight: bold;
    letter-spacing: 1px;
}

/* Animation for fading in lines */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.line {
    opacity: 0;
    transition: opacity 2s ease-in-out;
    position: relative; /* Needed for positioning the pseudo-element */
    padding-left: 30px; /* Create space for bullet, adjust as needed */
    display: flex;
    align-items: center;
}

.line.animate {
    animation: fadeIn 1s ease forwards;
}

.line::before {
    content: '•'; /* Unicode character for bullet */
    color: #32CD32; /* Bullet color */
    position: absolute;
    left: 0; /* Align bullet to the left */
    margin-top: -5px; /* Adjust vertical alignment */
    font-size: 2.0rem; /* Bullet size, adjust as needed */
}

.ab-right .line:nth-of-type(1) { animation-delay: 0.5s; }
.ab-right .line:nth-of-type(2) { animation-delay: 1.0s; }
.ab-right .line:nth-of-type(3) { animation-delay: 1.5s; }
.ab-right .line:nth-of-type(4) { animation-delay: 2.0s; }
.ab-right .line:nth-of-type(5) { animation-delay: 2.5s; }
.ab-right .line:nth-of-type(6) { animation-delay: 3.0s; }
.ab-right .line:nth-of-type(7) { animation-delay: 3.5s; }

.abt-btn {
    display: inline-block; /* Makes the link behave like a button */
    background-color: #32CD32; /* Vibrant green for contrast */
    color: white;
    border: none;
    padding: 12px 20px;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
    text-align: center;
    text-decoration: none; /* Removes underline from link */
    margin-top: 20px;
}

.abt-btn:hover {
    background-color: #28a745; /* Slightly darker green on hover */
    color: #fff; /* Keep text white on hover */
    text-decoration: none; /* Ensures no underline on hover */
    transform: scale(1.05); /* Slightly enlarges the button on hover */
}
