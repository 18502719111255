body, html {
    margin: 0;
    padding: 0;
    height: 100%;
}

.skills {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100vh; /* Viewport Height */
    padding: 20px;
    box-sizing: border-box;
    position: relative; /* Set relative for positioning children absolutely */
    background-color: #000; /* Black background for the section */
    color: #fff; /* Set default text color to white */
}

.skills-header {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2; /* Ensure header is above other elements */
    color: #fff; /* Text color for visibility */
}

.skills-header h1 {
    text-align: center;
    padding-bottom: 10px;
    margin: 20px auto; /* Center the header */
    position: relative; /* Relative positioning for pseudo-element */
}

.skills-header h1::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%); /* Center the line */
    width: 50%; /* Line width as 50% of the element's width */
    height: 2px; /* Line thickness */
    background-color: #32CD32; /* Line color */
}

.skills-left, .skills-right {
    flex-basis: 45%; /* Adjust the width of each section */
    text-align: justify;
    z-index: 1; /* Ensure content is below the header */
}

.skills-left {
    padding-right: 20px;
    font-style: italic; /* Add any style you like */
}

.skills-right {
    position: relative;
    width: 45%; /* Match with flex-basis */
    height: 80vh; /* Adjust as needed */
    overflow: hidden; /* Ensure overflow content is not visible */
}

.skills-right p:nth-child(1) { top: 10%; left: 10%; }
.skills-right p:nth-child(2) { top: 20%; left: 20%; }
.skills-right p:nth-child(3) { top: 30%; left: 30%; }
.skills-right p:nth-child(4) { top: 40%; left: 40%; }
.skills-right p:nth-child(5) { top: 50%; left: 50%; }
.skills-right p:nth-child(6) { top: 60%; left: 60%; }
.skills-right p:nth-child(7) { top: 70%; left: 70%; }
.skills-right p:nth-child(8) { top: 80%; left: 80%; }
.skills-right p:nth-child(9) { top: 90%; left: 90%; }

.skills-right p {
    font-size: 20px; /* Increase font size */
    font-weight: bold;
    color: #32CD32; /* Vibrant green for better visibility */
    position: absolute;
    animation: rotate-skills 10s linear infinite;
}

/* Skills rotating diagonally */
@keyframes rotate-skills {
    0%, 100% { transform: translate(0, 0); }
    25% { transform: translate(-100%, -100%); }
    50% { transform: translate(-100%, 100%); }
    75% { transform: translate(100%, 100%); }
}

/* Responsive Design */
@media (max-width: 768px) {
    .skills {
        flex-direction: column;
        text-align: center;
    }

    .skills-left, .skills-right {
        flex-basis: auto;
    }

    .skills-header h1 {
        font-size: 24px;
    }

    .skills-right {
        height: auto;
    }
}
