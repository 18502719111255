html {
    margin: 0;
    padding: 0;
    width: 100vw; /* Set width of the html to viewport width */
    overflow-x: hidden; /* Hide horizontal overflow */
}

body {
    margin: 0;
    padding: 0;
    width: 100%; /* Set width to 100% of the parent (html) */
    overflow-x: hidden; /* Hide horizontal overflow */
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    background-color: black;
    width: 100%; /* Ensure it spans the full width */
}

.navbar img {
    height: 60px;
    width: auto;
}

.text-links {
    display: flex;
    align-items: center;
    margin-right: 50px;
}

.text-links a, .text-links a:hover, .text-links a:visited {
    text-decoration: none; /* Removes underline from links */
}

.text-links p {
    margin-left: 20px; 
    color: #fff;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 4px;
    transition: background-color 0.2s ease, transform 0.2s ease, font-size 0.2s ease, color 0.2s ease;
}

.text-links p:hover {
    background-color: #28a745; /* Slightly darker green on hover */
    color: #fff; /* Keep text white on hover */
    font-size: 16px; /* Increased font size on hover */
    transform: translateY(-3px); /* Slight upward movement */
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.5); /* Subtle shadow matching the hover color */
}

/* ... Rest of your CSS for .skills, .skills-header, etc. ... */
