body, html {
    margin: 0;
    padding: 0;
    height: 100%;
}

.contact {
    text-align: center;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: black;
    color: white;
    min-height: 100vh;
}

.contact h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    margin-top: 100px;
}

.contact p {
    font-size: 1.2em;
    margin-bottom: 30px;
    margin-top: 50px;
}

.contact-button {
    font-size: 1.1em;
    margin-top: 40px;
    padding: 10px 20px;
    background-color: #32CD32;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out;
}

.contact-button:hover {
    background-color: #28a745;
    transform: scale(1.05);
}

.mail {
    position: fixed;
    top: 0;
    right: -400px;
    width: 400px;
    height: 100vh;
    background-color: #f3f3f3;
    box-shadow: -5px 0px 10px rgba(0,0,0,0.5);
    padding: 20px;
    box-sizing: border-box;
    transition: right 0.4s ease;
    z-index: 1000;
    color: #333; /* Ensuring text is visible */
}

.mail.active {
    right: 0;
}

.mail h1 {
    color: #333;
}

.mail form {
    display: flex;
    flex-direction: column;
}

.mail label {
    margin-top: 10px;
    color: #333; /* Dark color for visibility */
}

.mail input[type="text"],
.mail input[type="email"],
.mail textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.mail input[type="submit"] {
    background-color: #32CD32;
    color: white;
    padding: 10px 20px;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.mail input[type="submit"]:hover {
    background-color: #28a745;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: #ff6347; /* Tomato color for visibility */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.close-btn:hover {
    background-color: #e53e3e; /* Darker shade on hover */
}
