.experience {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
    padding-top: 60px;
    background-color: #000; /* Black background for the section */
    color: #fff; /* Set default text color to white */
}

.header-container {
    width: 50%;
    border-bottom: 1px solid #e0e0e0; /* Lighter border color for visibility */
    text-align: center;
    padding: 20px;
    margin: 0 auto;
}

.header-container h1 {
    font-size: 2.5rem;
    color: #e0e0e0; /* Light grey for better visibility */
    text-align: center;
    margin-bottom: 30px;
    font-family: 'Arial', sans-serif;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Adjusted for better visibility */
    font-weight: bold;
    letter-spacing: 1px;
}

.experience-container {
    text-align: center;
    animation: fadeIn 1s ease-in;
    width: 100%;
    flex-grow: 1;
}

.job-details {
    margin-top: 20px;
    /* Add any specific styling for job details if required */
}

/* Animation for fading in elements */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.description-container p {
    opacity: 0;
    transform: translateY(20px);
}

.description-container p.visible {
    animation: fadeInUp 3.5s ease forwards;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
